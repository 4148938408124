import { type AppProps } from 'next/app';

import 'newrelic';

import ErrorBoundary from '../components/atoms/ErrorBoundary/ErrorBoundary';

import '../styles/global.scss';

const MyApp = ({ Component, pageProps }: AppProps) => {
  return (
    <ErrorBoundary>
      <Component {...pageProps} />
    </ErrorBoundary>
  );
};

export default MyApp;
